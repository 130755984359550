import { debounce } from 'lodash'
import { humpToLine } from '@/utils/validate'
let topHeight = 0
export default {
  data() {
    return {
      list: [],
      queryData: {
        size: 15,
        current: 1
      },
      pageSizes: [10, 15, 20, 50, 100],
      pageLayout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      loading: false,
      tableHeight: 3000,
      hasInit: false
    }
  },
  methods: {
    fliterParams (data = {}, keys = ['id']) {
      const params = {}
      let length = 0
      if (!Array.isArray(keys)) {
        return data
      }
      keys.map(key => {
        if (data[key] || data[key] === 0) {
          params[key] = data[key]
          length++
        }
      })
      return length === keys.length ? params : data
    },
    changePage(e) {
      this.queryData.current = e
      this.getList()
    },
    handleSizeChange(e) {
      this.queryData.size = e
      this.search()
    },
    search() {
      this.changePage(1)
    },
    sort(e) {
      if (this.$route.path === '/match-mapping' && (e.prop === 'sourceHome' || e.prop === 'sourceAway')) {
        return
      }
      if (e.order) {
        const orderData = [
          {
            column: humpToLine(e.prop),
            asc: e.order === 'ascending'
          }
        ]
        this.queryData.orders = orderData
      } else {
        this.queryData.orders = undefined
      }
      this.search()
    },
    getList() {
      this.loading = true
      const extraData = this.fliterParams(this.extraData) || {}
      this.getData({ ...this.queryData, ...extraData })
        .then(res => {
          const data = res.data
          if (res.code === 0) {
            this.list = data.records || data.dataList
            this.total = data.total
            this.loading = false
          }
        })
        .catch(err => {
          this.list = []
          this.loading = false
        })
    },
    getHeight() {
      let fixHeight = 152 + topHeight
      this.tableHeight =
        window.innerHeight - fixHeight < 240
          ? 240
          : window.innerHeight - fixHeight
    }
  },
  mounted() {
    if (this.$el.querySelector('.el-form')) {
      topHeight = this.$el.querySelector('.el-form').clientHeight
    }
    this.$nextTick(this.getHeight)
    setTimeout(e => {
      !this.hasInit && this.getList()
    })
    window.addEventListener(
      'resize',
      debounce(() => {
        this.getHeight()
      }, 300)
    )
  }
}
