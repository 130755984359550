export default {
  // testTool
  // standard
  STD_SEARCH_EVENT_CODE: 'eventCode/list',
  STD_SEARCH_EVENT_TYPE: 'eventType/list',
  STD_SEARCH_SPORT: 'enum/sportList',
  STD_SEARCH_REGION: 'region/list',
  STD_SEARCH_TEAM: 'team/list',
  STD_SEARCH_PHASE: 'enum/tournamentPhaseList',
  STD_SEARCH_PLAYER: 'player/list',
  STD_SEARCH_TOURNAMENT: 'tournament/list',
  STD_SEARCH_MARKET_TYPE: 'enum/marketTypeList',
  STD_SEARCH_OUTRIGHT_MARKET_TYPE: 'language/queryLanguages',
  STD_SEARCH_OPTION: 'enum/optionList',
  STD_SEARCH_OUTRIGHT_OPTION: 'language/queryLanguages',
  STD_SEARCH_PERIOD: 'enum/periodList',
  STD_SEARCH_MATCH_PERIOD: 'enum/matchPeriodList',
  STD_SEARCH_MATCH_STATUS: 'enum/matchStatusList',
  getOperationDepartMentsUrl: 'operation/getOperationDepartments',
  getOperationUserNamesUrl: 'operation/getOperationUserNames',
  SEASON_LIST: 'special/season/list',
  TournamentPhaseList: 'enum/tournamentPhaseList',
  STD_SEARCH_TEAM_PLAYER: 'special/team/list'
  // subscribe
}
