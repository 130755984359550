import { get, put, post } from '@/utils/request'
import { ApiUrl } from './apiConfig'

export const StandardService = {
  fetchStandardStatus(params) {
    return get(ApiUrl.STD_STATUS, params)
  },

  fetchDataRequest(url, params) {
    return post(url, params)
  },

  fetchIntenationalMarkettList(params) {
    return get(ApiUrl.INTERNATIONAL_MARKET_TYPE_LIST, params)
  },

  fetchIntenationalMarketQuery(params) {
    return get(ApiUrl.INTERNATIONAL_MARKET_TYPE_QUERY, params)
  },

  putIntenationalMarket(params) {
    return put(ApiUrl.INTERNATIONAL_MARKET_TYPE_UPDATE, params)
  }
}
