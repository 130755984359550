<template>
 <div>
    Details<i class="el-icon-document operationDetails" @click="dialogVisible = true"></i>
    <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="350px">
    <span slot="title" class="dialog-title">
        <i class="el-icon-message"></i>
        {{dataType}}
        <span class="data-id">{{dataId}}</span>
    </span>
    <p class="history">Operation History <span class="num">({{list.length}})</span></p>
    <div>
        <el-form inline size="mini">
            <el-form-item label="URL:">
                <el-input v-model="extraData.requestUri" placeholder="Please"></el-input>
                <el-button type="primary" @click="onSubmit">Search</el-button>
            </el-form-item>
        </el-form>
        <ul class="infinite-list" style="overflow:auto;height:420px;border: 1px solid #cfd8e6;">
            <li v-for="(item, index) in list" class="list-item" :key="index">
                <p>{{parseTime(item.createTime, '{y}-{m}-{d} {h}:{i}:{s}')}}</p>
                <p>{{item.requestUriParam}}</p>
            </li>
            <p v-if="list.length === 0" style="color: #cfd8e6;margin-top: 60px;">no data</p>
        </ul>
    </div>
    </el-dialog>
 </div>
</template>

<script type="text/ecmascript-6">
import { getOperateLogDetail as getData } from '@/service/config'
import { operationOptions } from '@/utils/constants'
import { parseTime } from '@/utils'
export default {
 props: ['dataType', 'dataId', 'requestUri'],
 data() {
   return {
    dialogVisible: false,
    operationType: '',
    operationOptions,
    list: [],
    extraData: {
        size: 100,
        current: 1,
        dataType: '',
        dataId: '',
        requestUri: ''
    }
   }
 },
 components: {
 },
 watch: {
   dataId () {
      this.extraData.dataId = this.dataId || ''
   },
   dataType () {
      this.extraData.dataType = this.dataType || ''
   },
   dialogVisible () {
     if (this.dialogVisible) this.load()
   }
 },
 mounted () {
    this.extraData.dataType = this.dataType || ''
    this.extraData.dataId = this.dataId || ''
 },
 methods: {
    parseTime,
    load () {
       getData(this.extraData).then(res => {
           if (res.code === 0) {
               this.list = res.data
           }
       })
    },
    onSubmit () {
       this.load()
    }
 }
}
</script>

<style lang="scss" scoped>
  /deep/ .el-form-item--mini .el-form-item__content {
      display: flex;
  }
  /deep/ .el-button--mini {
      margin-left: 15px;
  }
  /deep/ .el-dialog__body {
    padding: 0 10px 10px 10px;
  }
  /deep/ .el-dialog {
    margin-top: 10vh !important;
    background-color: #EEF1F3;
  }
  .operationDetails {
    font-size: 20px;
    cursor: 'pointer';
    color: #000;
    margin-left: 8px;
  }
  .el-icon-message {
    font-size: 30px;
    margin-right: 5px;
  }
  .data-id {
      margin-left: 10px;
  }
  .dialog-title {
    display: flex;
    align-items: center;
  }
  /deep/.el-dialog__header {
    padding: 10px;
    padding-top: 15px;
  }
  /deep/ .el-form-item--mini.el-form-item {
    display: flex;
    width: 100%;
  }
  .history {
    text-align: left;
    height: 40px;
  }
  .num {
    color: red;
    font-size: 14px;
    margin-left: 5px;
  }
  .infinite-list {
    li {
      margin-bottom: 10px;
      background-color: #fff;
      min-height: 60px;
      border-radius: 5px;
    }
  }
  .list-item {
    padding: 5px;
    p {
        text-align: left;
    }
  }
</style>