<template>
  <el-select
    v-model="selected"
    filterable
    :remote="!options"
    clearable
    ref="select"
    @focus="getList('')"
    @change="change"
    :size="size"
    :disabled="disabled"
    :placeholder="placeholder"
    :remote-method="remote ? getList : null"
    :loading="loading"
    :multiple="multiple"
    :style="{ width: width + 'px' }"
    v-loadmore="loadMoreFun"
  >
    <el-option
      v-for="(item, index) in optionData"
      :key="item.label + labelKey + index"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
import axios from '@/utils/request'
export default {
  props: {
    remote:{
      default() {
        return true
      }
    },
    changeExtraParams: {
      type: [String, Number],
      default() {
        return null
      }
    },
    axiosMethods: {
      type: String,
      default() {
        return 'post'
      }
    },
    url: {
      type: String,
      default() {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    placeholder: {
      type: String,
      default() {
        return 'Please typing in keyword and select'
      }
    },
    labelKey: {
      type: String,
      default() {
        return 'label'
      }
    },
    valueKey: {
      type: String,
      default() {
        return 'value'
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      default() {
        return ''
      }
    },
    options: {
      type: Array,
      default() {
        return null
      }
    },
    size: {
      type: String,
      default() {
        return 'mini'
      }
    },
    loadMore: {
      type: Boolean,
      default() {
        return false
      }
    },
    width: {
      type: [String, Number],
      default() {
        return 240
      }
    },
    searchKey: {
      type: String,
      default() {
        return 'keyword'
      }
    },

    queryOrderParams: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      optionData: [],
      changed: false,
      total: 0,
      pagingParams: {
        size: 20,
        page: 1
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        const info = this.optionData.filter(item => {
          return item[this.valueKey] === val
        })
        this.$emit('update:value', val)
        this.$emit('change', val, info[0], this.changeExtraParams)
        this.changed = true
      }
    }
  },
  watch: {
    label: {
      immediate:true,
      handler() {
         this.$nextTick(this.fixLabel)
      }
    },
    value(val) {
      if (!this.changed) {
        this.$nextTick(this.fixLabel)
      }
    },
    options: {
      deep: true,
      handler(val, oldVal) {
        this.optionData = val
      }
    }
  },
  methods: {
    loadMoreFun() {
      if (this.loadMore && this.pagingParams.page < this.total) {
        console.log('loadmore')
      }
    },
    fixLabel() {
      this.$refs.select.query = this.label
      this.$refs.select.selectedLabel = this.label
      // this.$refs.select.selected.currentLabel = this.label
    },
    getList(e) {
      if (!this.url) return
      let queryData = {}
      queryData[this.searchKey] = e
      this.loading = true

      let params = {
        ...queryData,
        ...this.queryOrderParams
      }
      if (this.loadMore) {
        params = {
          ...params,
          size: 20,
          page: 1
        }
      }
      axios[this.axiosMethods](this.url, params)
        .then(res => {
          const responseData = res.data
          const selectOptions =
            responseData && !responseData.code
              ? responseData.data.dataList ||
                responseData.data.records ||
                responseData.data
              : []

          if (this.loadMore) {
            this.total = responseData.data.pages
            this.pagingParams = {
              size: 20,
              page: 1
            }
          }
          if (selectOptions.length) {
            const labelKeys = String(this.labelKey).split(',')
            this.optionData = selectOptions.map(item => {
              if (typeof item === 'string') {
                return {
                  label: item,
                  value: item
                }
              } else {
                let label = item && (item[this.labelKey] || item[labelKeys[0]])
                if(!label) {
                  try {
                    label = eval(`item.${this.labelKey}`)
                  } catch(e) {
                    console.log(e)
                  }
                }
                if (item && labelKeys.length > 1) {
                  labelKeys.forEach((val, index) => {
                    if (index > 0) {
                      label += ' - ' + item[val]
                    }
                  })
                }
                return {
                  ...item,
                  label: label,
                  value: item && item[this.valueKey]
                }
              }
            })
          } else {
            this.optionData = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    change(e) {}
  },
  mounted() {
    if (this.options) {
      this.optionData = this.options
    }
  }
}
</script>
