export const ApiUrl = {
  // test service api
  TEST_MATCHINFO: `/matchManager/insertMatchInfo`,
  TEST_SOURCEPRICE: `/marketManager/insertSourcePrice`,
  TEST_MATCHRESULT: `/matchManager/insertMatchResult`,
  TEST_MATCHEVENT: `/eventTypeManager/insertMatchEvent`,

  // standard service api
  STD_STATUS: `statusManager/queryStatusList`,

  // source service api

  // mapping service api
  MAP_SPORT: `sportTypeManager/updateSportMappingById`,
  MAP_REGION: `regionManager/updateRegionMappingById`,
  MAP_TEAM: `teamManager/updateTeamMappingById`,
  MAP_TOURNAMENT: `tournamentManager/updateTournamentMappingById`,

  // international service api
  INTERNATIONAL_MARKET_TYPE_UPDATE: `/languageManager/editMarketTypeInternational`,
  INTERNATIONAL_MARKET_TYPE_LIST: `/languageManager/queryMarketTypeInternational`,
  INTERNATIONAL_MARKET_TYPE_QUERY: `/marketManager/findMarketType`
}
