<template>
  <el-dropdown @command="handleCommand($event, row, methodStr)">
    <span class="el-dropdown-link">
      {{ text }}
      <i class="el-icon-caret-bottom el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <template v-for="item in options">
        <el-dropdown-item :key="item.value" :command="item.value">
          <el-badge :type="item.label" :value="item.value" />
        </el-dropdown-item>
      </template>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { RemarkOptions } from '@/utils/constants'

import {
  editRegion,
  editTeam,
  editTournament,
  editTeamRemark,
  remarkPlayer
} from '@/service/standard'
import {
  editSportMapping,
  editRegionMapping,
  editTeamMapping,
  editTournamentMapping,
  eidtMatchReamrk,
  playerUpdateMapping
} from '@/service/subscribe'

export default {
  // props: ['row', 'text', 'methodStr'],
  props: {
    text: {
      type: String,
      default() {
        return ''
      }
    },
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    methodStr: {
      type: String,
      default() {
        return ''
      }
    },
    options: {
      type: Array,
      default() {
        return RemarkOptions
      }
    }
  },
  data() {
    return {
      fetchMethod: {
        editRegion: params => editRegion(params),
        editTeam: params => editTeam(params),
        editTournament: params => editTournament(params),
        editSportMapping: params => editSportMapping(params),
        editRegionMapping: params => editRegionMapping(params),
        editTeamMapping: params => editTeamMapping(params),
        editTournamentMapping: params => editTournamentMapping(params),
        playerUpdateMapping: params => playerUpdateMapping(params),
        eidtMatchReamrk: params => eidtMatchReamrk(params),
        editTeamRemark: params => editTeamRemark(params),
        remarkPlayer: params => remarkPlayer(params),
      }
    }
  },
  methods: {
    async handleCommand(command, row, methodStr) {
      let params = {}
      params = {
        ...row,
        remark: command
      }

      try {
        const response = await this.fetchMethod[methodStr](params)
        !response.code && this.$emit('get-list')
        !response.code &&
          this.$message({
            message: 'Successful operation',
            type: 'success'
          })
      } catch (error) {
        this.$message.error('bad request', error)
      }
    }
  }
}
</script>
