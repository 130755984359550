import { cloneDeep } from 'lodash'
export default {
  data() {
    return {
      dialog: {
        title: 'Edit',
        name: '',
        isEdit: true,
        show: false,
        ref: 'diologForm',
        clickModal: false,
        isLoading: false
      },
      dialogForm: {}
    }
  },
  watch: {
    'dialog.show': function(val) {
      !val && this.reset()
    }
  },
  methods: {
    dialogShow(isEdit = 1, data = {}) {
      this.dialog.isEdit = isEdit
      this.dialog.title =
        (this.$route.meta.title || '') +
        (this.dialog.name ? this.dialog.name : isEdit ? ' Edit' : ' Add')
      this.$nextTick(() => {
        this.dialogForm = cloneDeep(data)
      })
      console.log(data)
      this.dialog.show = true
    },
    dialogHide() {
      this.dialog.show = false
    },
    dialogSubmit(isWarning = false) {
      isWarning = typeof isWarning !== 'object' && !!isWarning
      this.$refs[this.dialog.ref].validate(async valid => {
        if (valid) {
          this.dialog.isLoading = true
          if (isWarning) {
            this.$msgbox({
              title: 'Warning',
              type: 'warning',
              message: `Confirm to modify this ${this.$route.meta.title ||
                'data'}?`,
              showCancelButton: true,
              confirmButtonText: 'confirm',
              cancelButtonText: 'cancel'
            }).then(action => {
              this.dialogSendData()
            })
          } else {
            this.dialogSendData()
          }
        }
      })
    },
    async dialogSendData() {
      let data
      try {
        if (this.dialog.isEdit) {
          data = await this.editData(this.dialogForm)
        } else {
          data = await this.addData(this.dialogForm)
        }

        if (data.code === 0) {
          this.getList && this.getList()
          this.dialogHide()
          this.$message({
            message: 'Successful operation',
            type: 'success'
          })
        } else {
          this.dialog.isLoading = false
        }
      } catch (error) {
        this.dialog.isLoading = false
      }
    },
    reset() {
      this.dialog.isLoading = false
      this.dialogForm = {}
      try {
        this.$refs[this.dialog.ref] && this.$refs[this.dialog.ref].resetFields()
      } catch (e) {
        console.log(e)
      }
    },
    dialogEdit(row) {
      this.dialogShow(1, row)
    },
    dialogAdd(data) {
      this.dialogShow(0, data)
    }
  }
}
