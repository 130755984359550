<template>
  <el-autocomplete
    class="inline-input"
    placeholder="please enter"
    v-model="iptValue"
    :fetch-suggestions="querySearch"
    @select="handleSelect"
    :style="{ width: '240px' }"
    :disabled="disabled"
    clearable
  ></el-autocomplete>
</template>
<script>
import { StandardService } from '@/api/standardService'
import SearchUrl from '@/service/selectRemoteUrl'

export default {
  props: [
    'searchKey',
    'labelKey',
    'valueKey',
    'queryKey',
    'updateKey',
    'extraData',
    'row',
    'editRowIndex',
    'requestMethod',
    'getList',
    'queryOrderParams',
    'disabled'
  ],
  data() {
    return {
      SearchUrl,
      restaurants: [],
      iptValue: '',
      queryParams: {},
      requestParams: {}
    }
  },
  watch: {
    iptValue(newVal, oldVal) {
      this.$emit(`update:extraData`, newVal)
    }
  },
  methods: {
    async fetchData(queryString) {
      this.queryParams[this.queryKey] = queryString || ''
      const response = await StandardService.fetchDataRequest(
        SearchUrl[this.searchKey],
        { ...this.queryParams, ...this.queryOrderParams }
      )
      if (response.code !== 0) {
        return []
      } else {
        let data = Array.isArray(response.data)
          ? response.data
          : response.data.records
        const formatData = data.map(item => {
          item = { label: item[this.labelKey], value: item[this.valueKey] }
          return item
        })

        return formatData
      }
    },

    async querySearch(queryString, cb) {
      let restaurants = await this.fetchData(queryString)
      let results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants

      cb(results)
    },

    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !==
          -1
        )
      }
    },

    async handleSelect(item) {
      console.log(item)
      this.$emit('change', item)
      if (!this.row || !this.row.id) {
        return false
      }
      this.requestParams[this.updateKey] = item.label
      this.row[this.queryKey] = item.value
    
      try {
        const response = await this.requestMethod({
          ...this.row,
          ...this.requestParams
        })
        !response.code && this.$emit('get-list')
        !response.code &&
          this.$message({
            message: 'Successful operation',
            type: 'success'
          })
        this.$emit('update:editRowIndex', null)
      } catch (error) {
        this.$message.error('edit fail', error)
      }
    }
  }
}
</script>
