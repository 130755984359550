import Table from '../tableDialog'
import SelectBox from '@/components/Select'
import { InputAutocomplete } from '@/components'
import OperationType from '@/components/operationType'
import SEARCHAPIURLS from '@/service/selectRemoteUrl'
import {
  exportExcel
} from '@/service/standard'
export default {
  mixins: [Table],
  data() {
    return {
      ...SEARCHAPIURLS,
      downloding: false,
    }
  },
  components: {
    SelectBox,
    InputAutocomplete,
    OperationType
  },
  methods: {
    exportExcel(e) {
      this.downloding = true
      exportExcel(e).finally(e => {
        this.downloding = false
      })
    }
  }
}
