<template>
  <span>
  <el-upload
    class="logo-uploader"
    :action="upLoadUrl"
    :show-file-list="false"
    :on-success="handleLogoSuccess"
    :before-upload="handleLogoBefore"
    :on-error="onError"
    name="image"
    :headers="{ token }"
  >
    <div class="logo-upload-wrap" v-loading="upLoadLoading"  >
      <div v-if="url" class="file" slot="file">
      <img :src="url" class="team-logo" />
      <i class="el-icon-delete" @click.stop="remove"></i>
      </div>
      <i v-else class="el-icon-plus logo-uploader-icon"></i>
    </div>
  </el-upload>
  <span class="tips">Upload picture cannot exceed 25K</span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    url: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      upLoadUrl: `${process.env.VUE_APP_BASE_URL}/data-support/fileUploadManager/fileUpload`,
      upLoadLoading: false
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    remove() {
      this.$emit('update:url', '')
    },
    handleLogoSuccess(res) {
      this.upLoadLoading = false

      if (res.code === 0) {
        this.$emit('update:url', res.data)
      } else {
        this.$message({
          type: 'error',
          message: res.message
        })
      }
    },
    handleLogoBefore(file) {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.$message.error('Picture format is incorrect')
        return false
      }
      if (!isLt2M) {
        this.$message.error('Image size cannot exceed 2M')
        return false
      }

      this.upLoadLoading = true
      return true
    },

    onError(err) {
      this.upLoadLoading = false
      this.$message({
        type: 'error',
        message: 'Upload image failed！'
      })
      // console.log(err)
    }
  }
}
</script>

<style lang="scss">
.logo-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: v;
}
.logo-uploader .el-upload:hover {
  border-color: #409eff;
}
.logo-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.team-logo {
  width: 100px;
  height: 100px;
  display: block;
}
.file {
  position: relative;
  i{
    position: absolute;
    right: -6px;
    top: -6px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 2px;
    color: #666;
    &:hover{
      color: rgb(217, 111, 111);
      border-color: rgb(217, 111, 111);
    }
  }
}
.tips{
  color: #999;
  font-size: 12px;
}
</style>
