import { get, post, put, patch } from '@/utils/request'
import { Player } from '@/views/standard/international'
// eventCode
export const getSportMappingList = data => {
  return post('sport/mapping', data, 'json')
}

export const editSportMapping = data => {
  return put('sport/mapping', data, 'json')
}

// region
export const getRegionMappingList = data => {
  return post('region/queryMappingPage', data, 'json')
}

export const editRegionMapping = data => {
  return put('region/mapping', data, 'json')
}

// event code
export const getEventCodeMappingList = data => {
  return post('eventCode/mapping', data, 'json')
}

export const editEventCodeMapping = data => {
  return put('eventCode/mapping', data, 'json')
}

export const addEventCodeMapping = data => {
  return post('eventCode/saveMapping', data, 'json')
}
//

// event type
export const getEventTypeMappingList = data => {
  return post('eventType/mapping', data, 'json')
}

export const editEventTypeMapping = data => {
  return put('eventType/mapping', data, 'json')
}

// team 球队
export const getTeamMappingList = data => {
  return post('team/queryMappingPage', data, 'json')
}

export const editTeamMapping = data => {
  return put('team/mapping', data, 'json')
}

// tournament 联赛
export const getTournamentMappingList = data => {
  return post('tournament/queryMappingPage', data, 'json')
}

export const editTournamentMapping = data => {
  return put('tournament/mapping', data, 'json')
}

// match 三方赛事
export const getMatchMappingList = data => {
  return post('thirdmatch/pageQuery', data, 'json')
}

export const getMappingThirdMatchCounter = data => {
  return post('thirdmatch/getCounter', data, 'json')
}

// match 高亮
export const getMatchHighlight = thirdMatchId => {
  return get(`thirdmatch/getBasicMappingStatus?thirdMatchId=${thirdMatchId}`)
}

export const eidtMatchMappingCheckStatus = (thirdMatchId, checkStatus) => {
  return put(
    `thirdmatch/updateCheckStatus?thirdMatchId=${thirdMatchId}&checkStatus=${checkStatus}`
  )
}

// 更新三方赛事备注
export const eidtMatchReamrk = data => {
  return put(
    `thirdmatch/updateRemark?thirdMatchId=${data.thirdMatchId}&remark=${data.remark}`
  )
}

export const againMapping = thirdMatchId => {
  return post(`thirdmatch/againMapping?thirdMatchId=${thirdMatchId}`)
}

export const updateSetMatchInfo = data => {
  return patch('thirdmatch/manualMapping', data, 'formData')
}
export const updateIgnore = (data) => {
  return patch('thirdmatch/updateIgnore', data, 'formData')
}
export const updateReverse = (data) => {
  return patch('thirdmatch/updateReverse', data, 'formData')
}

export const findMatchInfoCount = data => {
  return get(`match/getById?matchId=${data}`)
}
// home away 获取standardName
export const queryThirdTeamByIds = data => {
  return post('team/queryThirdTeamBySourceIds', data, 'json')
}
// tournament 获取standardName
export const queryThirdTournamentBySourceIds = data => {
  return post('tournament/queryThirdTournamentBySourceIds', data, 'json')
}
// region 获取standardName
export const queryThirdRegionBySourceIds = data => {
  return post('region/queryThirdRegionBySourceIds', data, 'json')
}
// sport 获取standardName
export const queryThirdSportTypeBySourceIds = data => {
  return post('sport/queryThirdSportTypeBySourceIds', data, 'json')
}

// 手动映射三方赛事
export const manualMapping = data => {
  return patch(
    `thirdmatch/manualMapping?thirdMatchId=${data.thirdMatchId}&standardMatchId=${data.standardMatchId}`,
    'json'
  )
}
export const getStandardMatch = thirdMatchId => {
  return get(`match/getByThirdMatchId?thirdMatchId=${thirdMatchId}`)
}
export const createStandardMatchFromThirdMatch = data => {
  return post('match/createByThirdMatch', data, 'json')
}
export const getOutrightMarkets = data => {
  return post('outright/query', data, 'json')
}
export const getOutrightOptions = data => {
  return get('outright/getOutrightStandardMarkets', data, 'json')
}
export const getOutrightOptionsList = data => {
  return get('outright/getOutrightOptions', data, 'json')
}
export const updateOutrightMarketType = data => {
  return post('outright/updateOutrightMarketType', data, 'json')
}
export const getOutrightLanguageDetail = data => {
  return get('outright/getOutrightLanguageDetail', data, 'json')
}
export const batchEditInternational = data => {
  return post('language/batchEditInternational', data, 'json')
}
export const updateOutrightMarketStatus = data => {
  return post('outright/updateOutrightMarketStatus', data, 'json')
}

// Player
export const playerMapping = (data) => {
  return post('player/mapping', data, 'json')
}
export const playerUpdateMapping = (data) => {
  return post('player/updateMapping', data, 'json')
}
